<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="justify-content-center justify-content-md-between" style="display: flex; margin-bottom: 24px; flex-wrap: wrap;">
        <router-link to="/save-listing" class="btn btn-primary listing-link" style="margin-right: 8px; font-style: normal;">List a Horse for Sale</router-link>
        <router-link to="/save-service" class="btn btn-primary listing-link" style="margin-right: 8px; font-style: normal;">List a Service you provide</router-link>
      </div>
      <h1 class="text-center">Listing/Advertising Plans and Pricing</h1>
      <p class="text-center mt-3 mb-4">Questions? Email <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a></p>
      <div class="card-deck mb-3 text-center">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.basic.name}}</h4>
          </div>
          <div class="card-body">
            <div v-if="($route.query.next_page === 'save-listing' || $route.query.next_page === 'save-equipment') && $store.state.is_trainer_package_subscriber">
              <h1 class="card-title pricing-card-title"><span style="text-decoration: line-through;">${{plans.basic.prices.monthly.price}}</span> $0</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
              <p style="font-weight: bold;">Included with your Trainer/Breeder Package subscription</p>
            </div>
            <div v-else>
              <h1 class="card-title pricing-card-title">${{plans.basic.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The Basic Plan includes a map pin of the location of the service, horse, property or job. The listing is added to the &quot;list&quot; option on the search function. The map pin and listing will show up in a search if the user puts your specific location (by region or zip code) in their &quot;search by&quot; method as well as if they open their search to &quot;all locations.&quot; <i>(Please download the Mane Street Market app or visit www.manestreetmarket.com to see examples of map pins and list options.)</i>
            </p>
          </div>
          <div class="card-footer">
            <router-link v-if="$route.query.next_id && ($route.query.next_page === 'save-listing' || $route.query.next_page === 'save-equipment') && $store.state.is_trainer_package_subscriber" :to="{path: $route.query.next_page, query: {id: $route.query.next_id, payment_completed: 1}}" type="button" class="btn btn-lg btn-block btn-primary">Select & Publish</router-link>
            <router-link v-else-if="$route.query.next_id && $route.query.next_page" :to="{name: 'subscribe', params: {id: plans.basic.code}, query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
          </div>
        </div>
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.regional.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.regional.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The Premium Plan includes the benefits of the Basic Listing (map pin/list on both website and app) plus a scrolling ad on the website which gives upgraded visibility. The Premium advertisement rotates on all search options on desktop. <i>(Please visit www.manestreetmarket.com and click on Horses for Sale or Service Providers to see website ads.)</i>
            </p>
          </div>
          <div class="card-footer">
            <router-link v-if="$route.query.next_id && $route.query.next_page" :to="{name: 'subscribe', params: {id: plans.regional.code}, query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
          </div>
        </div>
        <!-- <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.national.name}}</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">${{plans.national.prices.monthly.price}} <small class="text-muted">/ mo</small></h1>
            <p>
              App and Website Advertiser includes benefits of Website Advertiser and Basic Listing plus rotating banner advertisement on app. Ads on web and mobile apps include a hyperlink to advertiser's website. This option provides maximum exposure to the MSM audience. Please contact support@manestreetmarket.com for ad specs before choosing this option. <i>(Please download the Mane Street Market app to see current app banner ads scrolling on the bottom of the screen.)</i>
            </p>
          </div>
          <div class="card-footer">
            <router-link v-if="$route.query.next_id && $route.query.next_page" :to="{name: 'subscribe', params: {id: plans.national.code}, query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
          </div>
        </div> -->
        <div class="card mb-4 box-shadow" v-if="!$route.query.next_id && !$route.query.next_page">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.trainer_package.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.trainer_package.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The {{plans.trainer_package.name}} is ideal for trainers/sellers who consistently have 5 or more horses/equipment for sale each month. You can add as many horses/equipment as you wish under the Basic Plan option for each horse/equipment for the monthy subscription rate of ${{plans.trainer_package.prices.monthly.price}}.
            </p>
          </div>
          <div class="card-footer">
            <router-link to="/trainer-package" type="button" class="btn btn-lg btn-block btn-primary">{{ $store.state.is_trainer_package_subscriber ? 'Manage Subscription' : 'Get Started' }}</router-link>
          </div>
        </div>
      </div>
      <div class="text-center" style="margin: 0 auto; width: 400px; max-width: 100%; margin-bottom: 50px;">
        <router-link v-if="$route.query.next_id == null || $route.query.next_page == null" to="/save-listing" type="button" class="btn btn-lg btn-block btn-primary" style="font-size: 14px; margin-bottom: 8px !important; width: 100%;">List a Horse for Sale</router-link>
        <router-link v-if="$route.query.next_id == null || $route.query.next_page == null" to="/save-service" type="button" class="btn btn-lg btn-block btn-primary" style="font-size: 14px; width: 100%;">List a Service you provide</router-link>
      </div>
      <!--
      <div class="text-center">
        <h3 class="blue-top-border"><span></span>ADVERTISE ON MSM WEBSITE AND MOBILE APP</h3>
        <router-link to="/save-ads" class="btn btn-primary">Click here to Advertise</router-link>
      </div>
      -->
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import plans from '@/constants/plans';

export default {
  components: {
    BaseLayout,
    SubHeader
  },
  data() {
    return {
      plans: plans
    }
  },
  async mounted() {
    document.title = 'Subscribe - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss" scoped>
  .pricing-card-title {
    font-size: 28px;
  }
  
  .listing-link {
    margin: 0 !important;
    margin-top: 8px !important;
  }
</style>